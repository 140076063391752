export default {
    name: "first-table",
    props: {
        item: {
            type: Object,
            default: {}
        },
        title: {
            type: String,
            default: () => ''
        },
        tableTitle: {
            type: String,
            default: () => ''
        }
    },
    components: {},

    data() {
        return {}
    }
}
