<template>
  <div class="small-loader">
    <div id="small-loader">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'profile-loader',
}
</script>
<style lang="scss">


</style>
