import {mapActions, mapGetters, mapMutations} from "vuex";

//sections
import inputRange from '@/components/input-range/index.vue'
import smallTable from '@/modules/product-card/pages/snow-load/small-table/index.vue'
import firstTable from "@/modules/product-card/pages/snow-load/first-table/index.vue";
import secondTable from "@/modules/product-card/pages/snow-load/second-table/index.vue";
import profileLoader from "@/components/misc/profileLoader.vue";

export default {
    name: "snow-load",
    components: {
        inputRange,
        smallTable,
        firstTable,
        secondTable,
        profileLoader
    },
    data() {
        return {
            selectedCalculator: {},
            range: '',
            width: 0.25,
            height: 0.25,
            rangeV: '',
            rangeThumb: '',
            value: 0.25,
            lengthMin: 0,
            lengthMax: 0,
            lengthStep: 0,
            widthMin: 0,
            widthMax: 0,
            widthStep: 0,
            thumbPosition: '',
            point: [],
            point2: [],
            products: [],
            widthKey: 1,
            lengthKey: 2
        }
    },
    async mounted() {

        if (this.$route.name !== 'profile-snow-load') {
            document.title = `${this.currentProduct.title + ' - ' + this.$t('productCard.snowLoadCalculation.title')} `
        }

        await this.getCategories()

        this.selectedCalculator = this.currentProduct.category
            || this.snowLoadCategories[0]

        await this.getCurrentCalculator({slug: this.selectedCalculator.slug})

        this.renderCalculator(this.currentSnowLoadCalculator.lengths)
        this.widthKey += 1
        this.lengthKey += 2
    },
    watch: {
        'selectedCalculator': function () {
            this.getCurrentCalculator({slug: this.selectedCalculator.slug}).then(() => {
                this.renderCalculator(this.currentSnowLoadCalculator.lengths)
                this.widthKey += 1
                this.lengthKey += 2
            }).catch(() => {
                console.log()
            })
        }
    },
    created() {
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
            snowLoadCategories: 'constructors/snowLoadCategories',
            currentSnowLoadCalculator: 'constructors/currentSnowLoadCalculator',
            loadingSnowCalculator: 'constructors/loadingSnowCalculator',

        }),
        showMultipleCalculators() {
            return this.$route.name === 'profile-snow-load'
        }
    },

    methods: {
        ...mapMutations({}),
        ...mapActions({
            getCategories: 'constructors/GET_SNOW_LOAD_CATEGORIES',
            getCurrentCalculator: 'constructors/GET_CURRENT_CALCULATOR'
        }),
        renderCalculator(item) {
            this.products = []
            if (item) {
                this.point = Object.keys(item)


                this.lengthMax = parseInt(this.point[this.point.length - 1])
                this.lengthStep = parseInt(this.point[1] - this.point[0])
                let values = Object.values(item)[0]
                let width = []
                values.forEach(el => {
                    width.push(Object.keys(el)[0])
                })

                this.height = parseInt(this.point[0])
                this.point2 = [...new Set(width)];
                this.widthMin = parseInt(width[0])
                this.widthMax = parseInt(width[width.length - 1])
                this.widthStep = parseInt(width[1] - width[0])
                this.lengthMin = parseInt(this.point[0])
                this.widthKey += 1
                this.lengthKey += 2


            }
        },
        getSelectedWidth(item) {

            let width = []
            for (let key in item) {
                if (parseInt(this.height) === parseInt(key)) {
                    item[key].forEach(el => {
                        width.push(Object.keys(el)[0])
                    })
                }
            }
            this.point2 = [...new Set(width)];
            this.widthMin = parseInt(width[0])
            this.widthMax = parseInt(width[width.length - 1])
            this.widthStep = parseInt(width[1] - width[0])


        },
        getSelectedAttributes(item) {
            for (let key in item) {
                if (parseInt(this.height) === parseInt(key)) {
                    for (let val in item[key]) {
                        for (let width in item[key][val]) {
                            if (parseInt(width) === parseInt(this.width)) {
                                this.products = item[key][val][width]
                            }
                        }
                    }

                }
            }
        },
        selectCalculator(item) {
            this.selectedCalculator = item
        }
    }
}
