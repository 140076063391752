export default {
    name: "second-table",
    props: {
        item: {
            type: Object,
            default: {}
        },
        title: {
            type: String,
            default: () => ''
        },
        tableTitle: {
            type: String,
            default: () => ''
        }
    },
    components: {},

    methods: {
        findElement(item, load, area) {
            let key = Array.from(Object.keys(item))
            let areaItem = key.find(el => (el.toUpperCase() === area))
            for (let k in item) {
                if (k === areaItem) {
                    return item[k][load]
                }
            }
        }
    },
    data() {
        return {}
    }
}
